import { useContext } from "react";
import { StateContext } from "../../../context";
import AppContext from "../../../custom-context/appContext";
import cn from "classnames";
import styles from "./Nav.module.scss";
import cropped_logo_blue from "../../../assets/cropped-logo-blue.png";
import { UPGRADE_ACCOUNT_URL } from "../../../utils/constants/app-constants";

const Header = () => {
  const { numLiveFlights, isSidenavOpen, toggleSidenav } =
    useContext(StateContext);

  // getting the user role via app context to display the correct nav items *Garvit*
  const { userLevel, aiSearchAircraftLimitation, isUnlimitedAiSearchAircraft } = useContext(AppContext);
  
  const jetFinderRoles = ['pro_jet_finder','unlimited_jet_finder','jet_finder','professional_jet_finder']
  const isCurrentPath = (path) => {
    return window.location.pathname === path;
  };

  const navItem = (path, text, icon, allowedRoles) => {
    // only allowing  access if the current logged in users role matches one of the roles specified for that page *Garvit*
    if (!allowedRoles.includes(userLevel)) {
      return null;
    }

    return (
      <a
        href={path}
        className={`${
          isCurrentPath(path) ? `nav-item-active` : `nav-item`
        } hidden md:flex items-center gap-2`}>
        <i className={`fa-solid fa-${icon}`}></i>
        <h3>{text}</h3>
      </a>
    );
  };

  return (
    <header className={cn(styles.header)}>
      <div className="flex items-center justify-center md:justify-start gap-4 md:gap-6 px-4 md:px-8 py-4 bg-white w-full">
        {
          userLevel && (
            <button
              onClick={toggleSidenav}
              className={cn(styles.menu_button, "block md:hidden")}
              style={{ width: 32 }}>
              {isSidenavOpen ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-sharp fa-solid fa-bars"></i>
              )}
            </button>
          )
        }
        <a href={ userLevel ? "/search" : "/"} className="shrink-0 ml-auto mr-auto md:ml-0 md:mr-4">
          <img
            src={cropped_logo_blue}
            alt="logo"
            className={cn(styles.nav_logo)}
          />
        </a>
        {/**Adding a list of allowed roles to the navItem function to only render the allowed menu items *Garvit* */}
        {navItem("/", "Dashboard", "house", [
          "basic_dashboard",
          "pro_dashboard",
          "owner_dashboard",
          ...jetFinderRoles
        ])}
        {navItem("/lookup", "Lookup", "binoculars", [
          "pro_dashboard",
          "owner_dashboard",
          ...jetFinderRoles])}
        {/* {navItem("/routes", "Routes", "route")} */}{" "}
        {navItem("/airport-lookup", "Airports", "plane-departure", [
          "pro_dashboard",
          "owner_dashboard",
          ...jetFinderRoles])}
        {navItem("/operators", "Operators", "earth-americas", [
          "pro_dashboard",
          "owner_dashboard",
          ...jetFinderRoles])}
        {navItem("/jet-finder", "Jet Finder", "search", [...jetFinderRoles])}
        {navItem("/aircrafts", "Directory", "folder-open", [...jetFinderRoles])}
        {navItem("/search", "Search", "plane", ["new_sep_basic","new_sep_mid","new_sep_top","pro_search",...jetFinderRoles])}
        {navItem("/shared-links", "Shared Links", "share", ["new_sep_top","new_sep_mid"])}
        <div className={`${cn(styles.nav_item)} ml-auto hidden md:flex`}>
          {/*<div className={`${cn(styles.sub_item)} `}>
            <div className={cn(styles.status_icon)} />
            <p>Status</p>
          </div>*/}
        </div>
        {/* Live flights */}
        {/* <div className={`${cn(styles.nav_item)} hidden md:flex `}>
          <div className={cn(styles.sub_item)}>
            <p>
              <i className="fa-regular fa-paper-plane"></i>
            </p>
            <p>{numLiveFlights}&nbsp;Live Flights</p>
          </div>
        </div> */}
        {
          userLevel && (
            <>
              <div>
                {
                  isCurrentPath('/jet-finder') && (
                    <p className='text-[#8000FF] font-bold'>
                      {isUnlimitedAiSearchAircraft ? "Unlimited Requests":`${aiSearchAircraftLimitation} Requests Left`}
                    </p>
                  ) 
                }
                {
                  !['professional_jet_finder','new_sep_top','new_sep_basic','new_sep_mid'].includes(userLevel) && (
                    <a
                    href={UPGRADE_ACCOUNT_URL}
                    rel='noreferrer'
                    target='_blank'
                    className='px-2 py-1 rounded-lg flex justify-center items-center gap-2 !bg-[#907EFF] !text-white text-center capitalize text-s'
                  >
                    <p>Upgrade My Subscription</p>
                    <i className='fa-solid fa-up-right-from-square !text-white'></i>
                  </a>
                  )
                }
              </div>
              <div className="w-10 h-10 bg-gray-100 rounded-full hidden md:flex items-center justify-center nav-item cursor-pointer">
                <a href="https://compareprivateplanes.com/my-account">
                  <i className="fa-solid fa-user"></i>
                </a>
              </div>
            </>
          )
        }
      </div>
    </header>
  );
};

export default Header;
